<template>
  <div style="width: 100%;">
    <!-- <v-divider class="my-2"></v-divider> -->
    <v-row dense no-gutters>
      <v-col cols="12">
        <Table
          :key="tableKey"
          :items="logs"
          :loading="loading"
          @fetchLogs="fetchLogs($event)"
        ></Table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Table from './LogsEventsDatatable'
import {
  getEventsUsingGET as getLogs
} from '@/utils/api'
import { mapActions } from 'vuex'

export default {
  components: {
    Table
  },
  data() {
    return {
      err: '',
      success: '',
      tableKey: 0,
      loading: false,
      logs: { items: [], meta: {} }
    }
  },
  computed: {},
  created() {
    if (this.$route.params && this.$route.params.message) {

      this.addSnackbar({
        message: this.$route.params.message,
        timeout: 5000,
        color: 'success'
      })
    }
  },
  methods: {
    ...mapActions('app', ['addSnackbar']),

    fetchLogs(options) {

      this.err = ''
      if (options) {
        if (options.dateFrom > options.dateTill) {
          this.addSnackbar({
            message: this.$lang.errors.dateWrong,
            timeout: 5000,
            color: 'error'
          })

          return
        }
      }

      const obj = {
        dateFrom: options ? options.dateFrom : '',
        dateTill: options ? options.dateTill : ''
      }

      if (options) {
        if (options.options.itemsPerPage !== -1) {
          obj.page = options.resetPage ? 1 : options.options.page || 1
          obj.size = options.options.itemsPerPage
        }
      } else {
        obj.page = 1
        obj.size = 25
      }

      if (options?.options?.sortDesc && options.options.sortDesc.length > 0) obj.orderDirection = options.options.sortDesc[0] ? 'DESC' : 'ASC'

      if (options && options.options && options.options.sortBy && options.options.sortBy.length > 0) {
        obj.orderField = options.options.sortBy[0]
      }

      if (options && options.processId) {
        obj.processId = options.processId
      }

      if (options && options.status) {
        obj.status = options.status
      }

      if (options && options.triggerId) {
        obj.triggerId = options.triggerId
      }

      if (options && options.type) {
        obj.type = options.type
      }

      this.loading = true
      getLogs(obj)
        .then((res) => {
          if (res.status !== 200) {
            this.loading = false
            this.err = res.response.data.statusText

            return
          }

          this.logs = { ...res.data.data, items: res.data.data.items.map((x) => {
            x.isLoading = false

            return x
          }) }
          this.loading = false
        })
        .catch((error) => {
          this.loading = false

          this.addSnackbar({
            message: error,
            timeout: 5000,
            color: 'error'
          })
          console.log(error)
        })
    }
  }
}
</script>
