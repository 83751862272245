<template>
  <v-container fluid class="pa-2 ma-0 Chart">
    <v-row
      v-if="!loading"
      wrap
      no-gutters
      align="center"
      justify="center"
    >
      <v-col cols="12" class="text-center pb-4">
        <v-row
          wrap
          no-gutters
          align="center"
          justify="space-between"
        >
          <v-col cols="12" class="pt-2 pt-md-0 text-right justify-end">
            <v-btn icon color="primary" class="mx-0" @click="fetchStatisticsData()">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" class="pt-2 pt-md-0">
            <v-data-table
              :headers="headers"
              :items="fetchedStatisticsData"
              item-key="status"
              class="elevation-0 background-transparent"
              hide-default-footer
              :items-per-page="-1"
              :sort-by="['count']"
              :sort-desc="[true]"
            >
              <template v-slot:item.status="{ item }">
                <div style="min-width: 250px">
                  <v-chip
                    :color="colorByStatus[item.status]"
                    text-color="white"
                  >
                    {{ $lang.status[item.status] }}
                  </v-chip>
                </div>
              </template>
              <template v-slot:item.count="{ item }">
                <div style="min-width: 250px">
                  <v-chip
                    text-color="white"
                  >
                    {{ item.count }}
                  </v-chip>
                </div>
              </template>
              <template v-slot:item.actions="{ item }">
                <div class="d-inline-flex">
                  <v-btn color="primary" class="mx-0 button-default-width" @click="setStatusDetails(item.status)">{{ $lang.actions.details }}</v-btn>
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="selectedStatus && showDetails" cols="12" class="text-center pb-4">
        <v-row
          wrap
          no-gutters
          align="center"
          justify="space-between"
        >
          <v-col cols="12" class="pt-2 pt-md-0 text-left">
            <h3>{{ selectedStatus ? $lang.status[selectedStatus] : '' }}</h3>
          </v-col>
          <v-col cols="12" class="d-flex pt-2 justify-space-between">
            <v-autocomplete
              v-model="processId"
              outlined
              dense
              hide-details
              :items="formattedProcesses"
              hide-no-data
              clearable
              item-text="formattedProcessName"
              item-value="processId"
              :label="$lang.labels.filterByProcess"
              :placeholder="$lang.actions.startTyping"
              :style="$vuetify.breakpoint.mdAndDown ? 'max-width: unset' : 'max-width: 50%'"
            />
            <v-btn icon color="primary" class="ml-2" @click="fetchStatisticsDetailsData()">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-data-table
              :headers="headersDetails"
              :items="statusDetailsData"
              item-key="status"
              class="elevation-0 background-transparent"
              :options.sync="optionsDetails"
              :server-items-length="totalItemsDetails"
              :footer-props="{
                'items-per-page-options': rowsPerPageItemsGlobal
              }"
              :loading="isLoadingDetails"
            >
              <template v-slot:item.actions="{ item }">
                <div class="d-inline-flex">
                  <v-btn color="primary" class="mx-0 button-default-width" @click="$emit('openEvent', { id: item.eventId, isLoading: false })">{{ $lang.labels.event }}</v-btn>
                  <v-btn color="primary" class="ml-1 button-default-width" :to="{ name: 'processEdit', params: { id: item.processId }, query: { eventId: item.eventId } }" target="_blank">{{ $lang.labels.debug }}</v-btn>
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      v-else
      wrap
      no-gutters
      align="center"
      justify="center"
    >
      <v-progress-circular indeterminate color="primary" />
    </v-row>
  </v-container>
</template>

<script>
import { getEventStatusStatisticsUsingGET, getProcessesNamesUsingGET, getEventsWithProcessesUsingGET } from '@/utils/api'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'EventsStatistics',
  components: {},
  props: {
    options: {
      type: Object,
      default: () => {}
    },
    totalItems: {
      default: 0,
      type: Number
    }
  },
  data() {
    return {
      loading: true,
      fetchedStatisticsData: [],
      statuses: ['SUCCESSFUL', 'NEW', 'IN_PROGRESS', 'FAILED', 'TIMEOUT', 'ON_HOLD', 'PROCESS_INACTIVE', 'FETCHED'].sort(),
      colorByStatus: {
        FAILED: '#FF5252',
        IN_PROGRESS: '#59bbbb',
        NEW: '#2196F3',
        ON_HOLD: '#696773',
        PROCESS_INACTIVE: '#FFC107',
        SUCCESSFUL: '#05c075',
        TIMEOUT: '#511127'
      },
      headers: [
        {
          text: this.$lang.labels.executions,
          align: 'start',
          sortable: true,
          value: 'count'
        },
        {
          text: this.$lang.labels.status,
          align: 'start',
          sortable: true,
          value: 'status'
        },
        { text: this.$lang.labels.actions, value: 'actions', align: 'end', sortable: false }
      ],
      statusDetailsData: [],
      initDetails: false,
      optionsDetails: {
        page: 1,
        itemsPerPage: 25,
        sortBy: [],
        sortDesc: [
          false
        ],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false
      },
      totalItemsDetails: 0,
      selectedStatus: '',
      showDetails: false,
      isLoadingDetails: false,
      headersDetails: [
        {
          text: this.$lang.labels.eventId,
          align: 'start',
          sortable: false,
          value: 'eventId'
        },
        {
          text: this.$lang.labels.processId,
          align: 'start',
          sortable: false,
          value: 'processId'
        },
        {
          text: this.$lang.labels.processName,
          align: 'start',
          sortable: false,
          value: 'processName'
        },
        { text: this.$lang.labels.actions, value: 'actions', align: 'end', sortable: false }
      ],
      processes: [],
      processId: ''
    }
  },
  computed: {
    ...mapState('app', ['rowsPerPageItemsGlobal']),
    formattedProcesses() {
      return this.processes.map((process) => ({
        ...process,
        formattedProcessName: `${process.processName} (${process.eventsCount})`
      })).sort((a, b) => b.eventsCount - a.eventsCount)
    }
  },
  watch: {
    options: {
      handler() {
        this.fetchStatisticsData()
      },
      deep: true
    },
    optionsDetails: {
      handler() {
        if (this.initDetails) this.fetchStatisticsDetailsData()
      },
      deep: true
    },
    processId: {
      handler() {
        this.optionsDetails.page = 1
        if (this.initDetails) this.fetchStatisticsDetailsData()
      }
    }
  },
  created() {
    this.fetchStatisticsData()
  },
  methods: {
    ...mapActions('app', ['addSnackbar']),

    setStatusDetails(status) {
      this.selectedStatus = status
      if (this.processId) {
        this.processId = ''
      } else {
        this.optionsDetails.page = 1
        this.fetchStatisticsDetailsData()
      }
    },
    async fetchProcessNames(obj) {
      const localObj = {
        page: 1,
        size: 1000,
        dateFrom: obj.dateFrom,
        dateTill: obj.dateTill,
        status: obj.status
      }

      this.isLoadingDetails = true

      try {
        const res = await getProcessesNamesUsingGET(localObj)

        this.processes = res.data.data.items

        return true
      } catch (error) {
        console.log(error)

        return false
      }
    },
    async fetchStatisticsDetailsData() {
      const obj = {
        dateFrom: this.options ? this.options.dateFrom : '',
        dateTill: this.options ? this.options.dateTill : ''
      }

      if (this.optionsDetails) {
        if (this.optionsDetails.itemsPerPage !== -1) {
          obj.page = this.optionsDetails.page || 1
          obj.size = this.optionsDetails.itemsPerPage
        }
      } else {
        obj.page = 1
        obj.size = 25
      }

      if (this.selectedStatus) {
        obj.status = this.selectedStatus
      }

      if (this.processId) {
        obj.processId = this.processId
      } else {
        await this.fetchProcessNames(obj)
      }

      this.isLoadingDetails = true
      getEventsWithProcessesUsingGET(obj)
        .then((res) => {
          this.statusDetailsData = res.data.data.items.map((event) => {
            const process = this.processes.find((p) => p.processId === event.processId)

            return {
              ...event,
              processName: process ? process.processName : 'Unknown process name'
            }
          })

          this.totalItemsDetails = res.data.data.meta.totalItems

          this.isLoadingDetails = false
          this.showDetails = true
          setTimeout(() => this.initDetails = true, 100)
        })
        .catch((error) => {
          this.isLoadingDetails = false
          this.addSnackbar({
            message: error,
            timeout: 5000,
            color: 'error'
          })
          console.log(error)
        })
    },
    fetchStatisticsData() {
      this.loading = true
      this.initDetails = false
      getEventStatusStatisticsUsingGET({ dateFrom: this.options.dateFrom, dateTill: this.options.dateTill })
        .then((res) => {
          this.fetchedStatisticsData = res.data.data
          this.statusDetailsData = []
          this.showDetails = false
          this.loading = false
          setTimeout(() => this.initDetails = true, 100)
        })
        .catch((error) => {
          this.loading = false
          console.log(error)
        })
    }
  }
}
</script>
<style lang="scss">
.Chart {
  background-color: var(--v-timelineBg-base) !important;
  border-radius: 5px;
  box-shadow: 0px 2px 15px rgba(25, 25, 25, 0.27);
}
</style>
